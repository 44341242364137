<div *ngIf="control">
  <p-multiSelect
    #contentInput
    selectedItemsLabel="{0} itens selecionadas"
    appendTo="body"
    styleClass="p-inputtext-lg inputfield w-full"
    [options]="options"
    [placeholder]="placeholder"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [formControl]="control"
    [emptyMessage]="emptyMessage"
    [emptyFilterMessage]="emptyFilterMessage"
    [readonly]="readonly"
    [showClear]="showClear"
    [filter]="showFilter"
    [ngClass]="{
      readonly: readonly,
      'ng-invalid ng-dirty': hasErrors
    }"
  >
  </p-multiSelect>
  <ng-container *ngFor="let error of errors">
    <small class="p-error">{{ getErrorMessage(control, error) }}<br /></small>
  </ng-container>
</div>
