import {Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {Table} from 'primeng/table';
import {TreeTable} from 'primeng/treetable';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
})
export class TableComponent {
  @ContentChild('rowExpanded') rowExpanded: TemplateRef<any>;

  @ViewChild('dt')
  set setDt(dt: TreeTable) {
    if (dt) {
      this.dt = dt;
    }
  }
  dt: TreeTable;

  @ViewChild('table')
  set setTable(_table: Table) {
    if (_table) {
      this.table = _table;
    }
  }
  table: Table;

  @ViewChild('tt')
  set setTT(tt: TreeTable) {
    if (tt) {
      this.tt = tt;
    }
  }
  tt: TreeTable;
  @Input() dataTableGridLines: boolean;
  @Input() scrollHeight: number;
  @Input() caption: string;
  @Input() checkboxable: boolean;
  @Input() columns: TableColumn[];
  @Input() dataKey: string;
  @Input() emptyMessage = 'Nenhum item encontrado';
  @Input() expandable: boolean;
  @Input() filterable: boolean;
  @Input() reorderableRows: boolean;
  @Input({required: true}) rows: any;
  @Input() selectable: boolean;
  @Input() selecteds: any[];
  @Input() selectionMode: 'single'|'multiple' = 'single';
  @Input() skeletonRowsCount = 10;
  @Input() stateKey: string;
  @Input() stateStorage: 'session'|'local' = 'local';
  @Input() treeTable: boolean;
  @Input() paginator: boolean = false;
  @Input() showCurrentPageReport: boolean = false;
  @Input() rowsPerPage: number = 5;
  @Input() rowsPerPageOptions: number[] = [5, 10, 20, 50, 100];
  @Output() selectedsChange = new EventEmitter<any[]>();
  @Output() aoExpandir = new EventEmitter<any>();
  @Output() aoReordenar = new EventEmitter<any>();
  @Output() aoSelecionar = new EventEmitter<any[]>();
  @Output() aoEditar = new EventEmitter<any>();

  get shownedColumns() {
    return this.columns?.filter((e) => !e.hidden);
  }
  get haveColspanHeader() {
    return this.columns.some(
        p => p.headerColspan?.length > 0 && p.headerColspanCount > 0);
  }
  get colspan() {
    let count = this.columns?.filter((e) => !e.hidden).length;
    if (this.reorderableRows) {
      count++;
    }
    if (this.expandable) {
      count++;
    }
    if (this.checkboxable) {
      count++;
    }
    if (this.selectable) {
      count++;
    }
    return count;
  }
  onRowSelect() {
    this.aoSelecionar.emit(this.selecteds);
    this.selectedsChange.emit(this.selecteds);
  }
  onRowExpand(event: any) {
    this.aoExpandir.emit(event);
  }
  onRowReorder(event: any) {
    this.aoReordenar.emit(event);
  }
  onRowEdit(rowData: any, rowIndex: number) {
    this.aoEditar.emit({'index': rowIndex, 'data': rowData});
  }
  onKeyPressed(event: KeyboardEvent, rowData: any, rowIndex: number) {
    if (event.key === 'Enter') {
      this.onRowEdit(rowData, rowIndex);
    }
  }
  checkFilterType(type: any, propType: any) {
    return type === propType;
  }
}
