import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {MenuItem} from 'primeng/api';

import {LayoutService} from './service/app.layout.service';

@Component({selector: 'app-topbar', templateUrl: './app.topbar.component.html'})
export class AppTopBarComponent implements OnInit {
  items!: MenuItem[];

  @ViewChild('menubutton') menuButton!: ElementRef;
  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;
  @ViewChild('topbarmenu') menu!: ElementRef;

  get usuarioLogado() {
    return this.authFacade.usuarioLogado;
  }

  constructor(
      public layoutService: LayoutService,
      private authFacade: AuthFacade,
      private router: Router,
  ) {}

  private criarMenuDrodown() {
    this.items = [
      {
        label: 'Minha Conta',
        icon: 'pi pi-fw pi-user',
        styleClass: 'text-center',
        command: () => this.router.navigate(['/minha-conta']),
      },
      {separator: true},
      {
        label: 'Sair',
        icon: 'pi pi-fw pi-power-off',
        command: () => this.sair(),
      },
    ];
  }

  ngOnInit(): void {
    this.criarMenuDrodown();
  }
  sair() {
    this.authFacade.logout();
  }
}
