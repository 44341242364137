import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';

import {AppLayoutComponent} from './modules/layout/app.layout.component';
import {usuarioLogado, usuarioNaoLogado} from './shared/guards/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
            import('./modules/home/home.module').then((m) => m.HomeModule),
      },
    ],
    canActivate: [usuarioLogado],
  },
  {
    path: 'auth',
    loadChildren: () =>
        import('./modules/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [usuarioNaoLogado],

  },
];

const routeConfig = <ExtraOptions>{
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routeConfig)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
