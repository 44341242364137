<ng-container *ngIf="control">
  <p-autoComplete
    #contentInput
    styleClass="p-inputtext-lg inputfield w-full"
    inputStyleClass="w-full"
    appUppercase
    appAutoFocus
    [autofocus]="autoFocus"
    [formControl]="control"
    [maxlength]="maxlength"
    [ngClass]="{
      'ng-invalid ng-dirty': hasErrors,
      'text-right': textAlign === 'right',
      'text-center': textAlign === 'center',
      'text-left': textAlign === 'left'
    }"
    (completeMethod)="completeMethodEvent($event)"
    (onSelect)="onSelectEvent($event)"
  >
    <ng-template let-item pTemplate="item">
      <ng-container
        *ngIf="template"
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{ $implicit: item }"
      ></ng-container>
      <span *ngIf="!template">{{ item.descricao }}</span>
    </ng-template>
  </p-autoComplete>

  <small
    style="float: right"
    *ngIf="maxlength"
    [ngClass]="{
      'p-error': hasErrors
    }"
    >{{ control.value?.toString().length ?? 0 }}/{{
      maxlength.toString()
    }}</small
  >
  <ng-container *ngFor="let error of errors">
    <small class="p-error">{{ getErrorMessage(control, error) }}<br /></small>
  </ng-container>
</ng-container>
