<ng-container *ngIf="control">
  <div class="p-inputgroup">
    <input
      #contentInput
      type="text"
      class="p-inputtext-lg inputfield w-full"
      pInputText
      appAutoFocus
      appUppercase
      appFoneMask
      [placeholder]="placeholder"
      [autofocus]="autoFocus"
      [formControl]="control"
      [readonly]="readonly"
      [maxlength]="maxlength"
      [ngClass]="{
        'ng-invalid ng-dirty': hasErrors,
        'text-right': textAlign === 'right',
        'text-center': textAlign === 'center',
        'text-left': textAlign === 'left',
        'w-full': fullWidth
      }"
      (blur)="onBlur($event)"
      (keydown)="onKeyDown($event)"
    />
    <p-button
      *ngIf="showButton"
      type="button"
      styleClass="btn-input-text-icon"
      [icon]="iconButton"
      (onClick)="onClick()"
      [disabled]="disabledButton"
      [pTooltip]="buttonTooltipText"
      [tooltipPosition]="buttonTooltipPosition"
    >
    </p-button>
  </div>
  <small
    style="float: right"
    *ngIf="maxlength"
    [ngClass]="{
      'p-error': hasErrors
    }"
    >{{ controlLength ?? 0 }}/{{ contador.toString() }}</small
  >
  <ng-container *ngFor="let error of errors">
    <small class="p-error">{{ getErrorMessage(control, error) }}<br /></small>
  </ng-container>
</ng-container>
