export enum API {
  Admin_Alimentos = 'admin/alimentos',
  Admin_Categorias = 'admin/categorias',
  Admin_Exercicios = 'admin/exercicios',
  Admin_GrupoMuscular = 'admin/grupos-musculares',
  Admin_Dashboard = 'admin/dashboard',
  Admin_Auth = 'admin/auth',
  Admin_Auth_Logar = 'admin/auth/logar',
  Admin_Auth_Logado = 'admin/auth/logado',
  Admin_Auth_Alterar_Senha = 'admin/auth/alterar-senha',
}