<label class="custom-label" [ngClass]="{ 'label-tab': labelTab }">
  <b
    >{{ texto }}<span class="p-error" *ngIf="obrigatorio">*</span
    ><span class="ml-1"
      *ngIf="textoSecundario"
      [ngStyle]="{ color: colorTextoSecundario }"
      >{{ textoSecundario }}</span
    ></b
  >
  <i
    *ngIf="cadastroRapido"
    class="pi pi-plus"
    (click)="adicionar()"
    pTooltip="Adicionar"
    tooltipPosition="bottom"
  ></i>
  <i *ngIf="info" class="pi pi-info-circle" [pTooltip]="info"></i>
  <i
    *ngIf="showIcon"
    [class]="iconOptions?.icon"
    [pTooltip]="iconOptions?.tooltip"
    [tooltipPosition]="iconOptions?.tooltipPosition ?? 'right'"
    (click)="iconOptions?.onClick()"
  ></i>
</label>
