import {Component, ElementRef, EventEmitter, Input, Output, ViewChild,} from '@angular/core';
import {FormControl} from '@angular/forms';

import {BaseInput} from '../base/base-input';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent extends BaseInput {
  @Input() upperCase: boolean = true;
  @Input() autoFocus: boolean;
  @Input() maxlengthCount: number = 0;
  @Input() foneMask: boolean;
  @Input() onlyCellPhoneMask: boolean;
  @Input() onlyPhoneMask: boolean;
  @Input() lengthOnlyNumber: boolean;
  @Input() textAlign: string = 'left';  // left, right, center
  @Input() fullWidth: boolean;          // left, right, center
  @Input() control: FormControl;
  @Input() mask: string;
  @Input() placeholder: string = '';
  @Input() readonly: boolean;
  @Input() maxlength: number;
  @Input() minlength: number;
  @Input() showButton: boolean;
  @Input() iconButton: string;
  @Input() disabledButton: boolean;
  @Input() showColorPicker: boolean;
  @Input() cpPositionOffset: string = '50%';
  @Input() cpPositionRelativeToArrow: boolean = true;
  @Input() buttonTooltipText: string;
  @Input() buttonTooltipPosition: string;
  @Input() prefixIcon: string;
  @Input() suffixIcon: string;
  @Output() buttonOnClick = new EventEmitter();
  @Output() inputBlur = new EventEmitter();
  @Output() inputKeydown = new EventEmitter();
  @Output() colorPickerChange = new EventEmitter();

  @ViewChild('contentInput')
  set _content(_input: ElementRef<any>) {
    if (_input) {
      this.contentInput = _input;
    }
  }
  contentInput: ElementRef<any>;
  get contador() {
    return this.maxlengthCount > 0 ? this.maxlengthCount : this.maxlength;
  }
  get controlLength() {
    return this.lengthOnlyNumber ?
        this.control?.value?.toString().replace(/[^0-9]/g, '').length :
        this.control?.value?.toString().length;
  }
  get hasErrors() {
    return this.control?.errors && this.control?.touched;
  }
  get errors() {
    if (!this.control?.errors || !this.control?.touched) return [];
    return Object.keys(this.control?.errors);
  }
  onClick() {
    this.buttonOnClick.emit();
  }
  onBlur(event: any) {
    this.inputBlur.emit(event);
  }
  onKeyDown(event: any) {
    this.inputKeydown.emit(event);
  }
  onColorPickerChange(event: any) {
    this.colorPickerChange.emit(event);
    this.control?.setValue(event);
  }
}
