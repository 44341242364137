<div class="p-inputgroup">
  <span
    class="w-full"
    [ngClass]="{
      'p-input-icon-right': showClear && model
    }"
  >
    <i
      class="pi pi-times cursor-pointer"
      *ngIf="showClear && model"
      (click)="clearInput()"
    ></i>
    <input
      #contentInput
      type="text"
      class="p-inputtext-lg inputfield w-full"
      pInputText
      appAutoFocus
      appUppercase
      appFoneMask
      [placeholder]="placeholder"
      [autofocus]="autoFocus"
      [readonly]="readonly"
      [maxlength]="maxlength"
      [ngClass]="{
        'text-right': textAlign === 'right',
        'text-center': textAlign === 'center',
        'text-left': textAlign === 'left'
      }"
      [(ngModel)]="model"
      [ngModelOptions]="modelOptions"
      (ngModelChange)="onNgModelChange($event)"
      (blur)="onBlur($event)"
      (keydown)="onKeyDown($event)"
    />
    <p-button
      *ngIf="showButton"
      type="button"
      [icon]="iconButton"
      (onClick)="onClick()"
      [disabled]="disabledButton"
    >
    </p-button>
  </span>
</div>
