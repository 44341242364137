import {CommonModule, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {JwtModule} from '@auth0/angular-jwt';
import {AppLayoutModule} from 'app/modules/layout/app.layout.module';
import {NgxUiLoaderConfig, NgxUiLoaderModule} from 'ngx-ui-loader';
import {ConfirmationService, MessageService} from 'primeng/api';

import {AppRoutingModule} from '../app-routing.module';

import {HttpErrorInterceptor} from './interceptors/http-error.interceptor';
import {HttpRequestInterceptor} from './interceptors/http-request.interceptor';

registerLocaleData(localePt);

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem('access_token');
        },
      }
    }),
    NgxUiLoaderModule.forRoot(<NgxUiLoaderConfig>{
      bgsColor: 'red',
      bgsOpacity: 0.5,
      bgsPosition: 'bottom-right',
      bgsSize: 60,
      bgsType: 'ball-spin-clockwise',
      blur: 2,
      delay: 0.1,
      fastFadeOut: true,
      fgsColor: '#27A37E',
      fgsPosition: 'center-center',
      fgsSize: 60,
      fgsType: 'three-strings',
      gap: 24,
      logoPosition: 'center-center',
      logoSize: 150,
      logoUrl: 'assets/images/brand.png',
      masterLoaderId: 'master',
      overlayBorderRadius: '0',
      overlayColor: 'rgba(40, 40, 40, 0.8)',
      pbColor: '#27A37E',
      pbDirection: 'ltr',
      pbThickness: 3,
      hasProgressBar: true,
      text: '',
      textColor: '#FFFFFF',
      textPosition: 'center-center',
      maxTime: -1,
      minTime: 300,
    }),
  ],
  exports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppLayoutModule,
    NgxUiLoaderModule,
  ],
  providers: [
    Title,
    ConfirmationService,
    MessageService,
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
    {provide: LOCALE_ID, useValue: 'pt-BR'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [AuthFacade],
    },
  ],
})
export class CoreModule {
}
