import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {SharedModule} from 'app/shared/shared.module';
import {BadgeModule} from 'primeng/badge';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RippleModule} from 'primeng/ripple';
import {SidebarModule} from 'primeng/sidebar';

import {AppFooterComponent} from './app.footer.component';
import {AppLayoutComponent} from './app.layout.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppSidebarComponent} from './app.sidebar.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppConfigModule} from './config/config.module';

@NgModule({
  declarations: [
    AppMenuitemComponent,
    AppTopBarComponent,
    AppFooterComponent,
    AppMenuComponent,
    AppSidebarComponent,
    AppLayoutComponent,
  ],
  imports: [
    AppConfigModule,
    BadgeModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    InputSwitchModule,
    InputTextModule,
    RadioButtonModule,
    RippleModule,
    RouterModule,
    SidebarModule,
    SharedModule,
  ],
  exports: [AppLayoutComponent]
})
export class AppLayoutModule {
}
