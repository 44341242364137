<div class="p-inputgroup">
  <textarea
    #contentInput
    class="p-inputtext-lg inputfield w-full"
    pInputTextarea
    appAutoFocus
    appUppercase
    appFoneMask
    [placeholder]="placeholder"
    [autofocus]="autoFocus"
    [readonly]="readonly"
    [rows]="rows"
    [maxlength]="maxlength"
    [ngClass]="{
      'text-right': textAlign === 'right',
      'text-center': textAlign === 'center',
      'text-left': textAlign === 'left',
      'w-full': fullWidth
    }"
    [(ngModel)]="model"
    [ngModelOptions]="modelOptions"
    (ngModelChange)="onNgModelChange($event)"
    (blur)="onBlur($event)"
    (keydown)="onKeyDown($event)"
  ></textarea>
</div>
