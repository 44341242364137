import {Injectable} from '@angular/core';
import {UsuarioAuth} from '@app/abstraction/domain/entities/usuario-auth.interface';
import {UsuarioConfig} from '@app/abstraction/domain/interfaces/usuario-config.interface';
import {JwtHelperService} from '@auth0/angular-jwt';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AuthStore {
  constructor(private jwtHelper: JwtHelperService) {}

  private readonly _access_token = new BehaviorSubject<string>(null);
  private readonly _usuarioLogado = new BehaviorSubject<UsuarioAuth>(null);
  private readonly _usuarioConfig = new BehaviorSubject<UsuarioConfig>(null);

  get access_token() {
    return this._access_token.value;
  }
  get access_token$() {
    return this._access_token.asObservable();
  }
  set access_token(access_token: string) {
    this._access_token.next(access_token);
  }
  get hasValidAccessToken() {
    this.access_token = localStorage.getItem('access_token');
    return this.access_token &&
        !this.jwtHelper.isTokenExpired(this.access_token);
  }

  get usuarioConfig(): UsuarioConfig {
    return this._usuarioConfig.getValue();
  }
  set usuarioConfig(usuarioConfig: UsuarioConfig) {
    this._usuarioConfig.next(usuarioConfig);
  }
  get usuarioConfig$() {
    return this._usuarioConfig.asObservable();
  }


  get usuarioLogado(): UsuarioAuth {
    return this._usuarioLogado.getValue();
  }
  set usuarioLogado(usuarioLogado: UsuarioAuth) {
    this._usuarioLogado.next(usuarioLogado);
  }
  get usuarioLogado$() {
    return this._usuarioLogado.asObservable();
  }
}
