// import '@app/shared/extensions/array.extension';

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '@app/abstraction/data/services/auth.service';
import {AuthStore} from '@app/abstraction/data/stores/auth.store';
import {AlterarSenhaModel} from '@app/abstraction/domain/model/alterar-senha.model';

import {LocalConfigFacade} from '../local-config/local-config.facade';

@Injectable({providedIn: 'root'})
export class AuthFacade {
  constructor(
      private service: AuthService,
      private store: AuthStore,
      private router: Router,
      private localConfigFacade: LocalConfigFacade,
  ) {}

  get usuarioLogado() {
    return this.store.usuarioLogado;
  }
  get isLoggedIn() {
    return this.store.hasValidAccessToken;
  }
  get usuarioConfig() {
    return this.store.usuarioConfig;
  }
  get usuarioConfig$() {
    return this.store.usuarioConfig$;
  }
  async buscarUsuarioLogado() {
    this.store.usuarioLogado = await this.service.buscarUsuarioLogado();
  }
  async login(email: string, senha: string) {
    if (!this.isLoggedIn) {
      return this.service.login(email, senha).then((token) => {
        this.store.access_token = token;
        localStorage.setItem('access_token', token);
      });
    }
    return null;
  }
  logout() {
    this.store.access_token = null;
    this.store.usuarioLogado = null;
    localStorage.removeItem('access_token');
    this.router.navigate(['/auth/login']);
  }
  alterarSenha(alterarSenha: AlterarSenhaModel) {
    return this.service.alterarSenha(alterarSenha);
  }
  salvarEstadoFiltro(filtro: any, stateKey: string) {
    if (!stateKey) return;
    this.usuarioConfig.filtros[stateKey] = filtro;
    this.salvarConfiguracoesDoUsuario();
  }
  buscarEstadoFiltro(filtro: any, stateKey: string) {
    if (!stateKey) return;
    const filtroSalvo = this.usuarioConfig.filtros[stateKey];
    if (filtroSalvo) Object.assignWithDate(filtro, filtroSalvo);
  }
  buscarConfiguracaoLocaisDoUsuario() {
    this.store.usuarioConfig =
        this.localConfigFacade.buscarConfiguracoesDoUsuario(
            this.usuarioLogado.email);
  }
  salvarConfiguracoesDoUsuario() {
    this.localConfigFacade.salvarConfiguracoesDoUsuario(
        this.usuarioLogado.email,
        this.usuarioConfig,
    );
  }
}
