
export class UsuarioAuth {
  nome: string;
  email: string;

  static from(json: any = {}): UsuarioAuth {
    const usuario = new UsuarioAuth();
    usuario.nome = json.nome;
    usuario.email = json.email;
    return usuario;
  }
}
