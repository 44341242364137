/* eslint-disable @angular-eslint/no-output-on-prefix */
import {Component, ContentChild, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';

import {BaseInput} from '../base/base-input';

@Component({
  selector: 'app-auto-complete-input',
  templateUrl: './auto-complete-input.component.html',
  styleUrls: ['./auto-complete-input.component.scss']
})
export class AutoCompleteInputComponent extends BaseInput {
  @ContentChild('template') template: TemplateRef<any>;
  @Input() upperCase: boolean = true;
  @Input() autoFocus: boolean;
  @Input() sugestoes: any[];
  @Input() maxlength: number;
  @Input() scrollHeight: string = '300px';
  @Input() emptyMessage: string = 'Nenhum item encontrado';
  @Input() textAlign: string = 'left';  // left, right, center
  @Input() control: FormControl;
  @Input() placeholder: string = '';
  @Input() readonly: boolean;
  @Input() panelStyle: any = {'max-width': '500px'};
  @Output() inputBlur = new EventEmitter();
  @Output() completeMethod = new EventEmitter();
  @Output() onSelect = new EventEmitter();

  @ViewChild('contentInput')
  set _content(_input: ElementRef<any>) {
    if (_input) {
      this.contentInput = _input;
    }
  }
  contentInput: ElementRef<any>;

  get hasErrors() {
    return this.control?.errors && this.control?.touched
  }
  get errors() {
    if (!this.control?.errors || !this.control?.touched) return [];
    return Object.keys(this.control?.errors);
  }
  onBlur(event: any) {
    this.inputBlur.emit(event);
  }
  onSelectEvent(event: any) {
    this.onSelect.emit(event);
  }
  completeMethodEvent(event: any) {
    this.completeMethod.emit(event);
  }
}
