import {HashLocationStrategy, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    SharedModule,
    RouterModule,
  ],
  providers: [
    [
      Location,
      {provide: LocationStrategy, useClass: PathLocationStrategy},
      {provide: LocationStrategy, useClass: HashLocationStrategy},
    ],
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
