import {Injectable} from '@angular/core';
import {LocalConfigService} from '@app/abstraction/data/services/shared/local-config.service';
import {UsuarioConfig} from '@app/abstraction/domain/interfaces/usuario-config.interface';

@Injectable({providedIn: 'root'})
export class LocalConfigFacade {
  constructor(
      private service: LocalConfigService,
  ) {}

  buscarConfiguracoesDoUsuario(usuarioId: string) {
    let config = this.service.buscarConfig(usuarioId);

    if (!config)
      config = this.service.salvarConfig(usuarioId, <UsuarioConfig>{
        filtros: {},
        colunas: {},
      });
    return config;
  }
  salvarConfiguracoesDoUsuario(
      usuarioId: string,
      config: UsuarioConfig,
  ) {
    this.service.salvarConfig(usuarioId, config);
  }
}