<ng-container *ngIf="control">
  <p-password
    #contentInput
    inputStyleClass="p-inputtext-lg inputfield w-full "
    styleClass="p-inputtext-lg inputfield w-full "
    [autocomplete]="'new-password'"
    [formControl]="control"
    [toggleMask]="toggleMask"
    [feedback]="feedback"
    [placeholder]="placeholder"
    [ngClass]="{
      'p-disabled': readonly,
      'password-readonly': readonly,
      'ng-invalid ng-dirty': hasErrors,
      'text-right': textAlign === 'right',
      'text-center': textAlign === 'center',
      'text-left': textAlign === 'left',
      'w-full': fullWidth
    }"
  ></p-password>
  <ng-container *ngFor="let error of errors">
    <small class="p-error">{{ getErrorMessage(control, error) }}<br /></small>
  </ng-container>
</ng-container>
