import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-titulo',
  templateUrl: './titulo.component.html',
  styleUrls: ['./titulo.component.scss'],
})
export class TituloComponent {
  breadcrumbs: MenuItem[] = [];
  constructor(
      private activatedRoute: ActivatedRoute,
  ) {
    this.montarBreadcrumbs();
  }
  private montarBreadcrumbs() {
    const data = this.activatedRoute.snapshot.data;
    if (data['modulo']) {
      this.breadcrumbs.push({
        label: data['modulo'],
      });
    }
    if (data['tela']) {
      this.breadcrumbs.push({
        label: data['tela'],
        routerLink: data['rota'],
      });
    }
    if (data['operacao']) {
      this.breadcrumbs.push({
        label: data['operacao'],
      });
    }
  }
}
