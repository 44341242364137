import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-dropdown-input-ngmodel',
  templateUrl: './dropdown-input-ngmodel.component.html',
  styleUrls: ['./dropdown-input-ngmodel.component.scss']
})
export class DropdownInputNgmodelComponent {
  @Input() options: any[];
  @Input() readonly: boolean;
  @Input() group: boolean;
  @Input() placeholder: string = 'Selecione';
  @Input() emptyMessage: string = 'Nenhum item encontrado';
  @Input() emptyFilterMessage: string = 'Nenhum item encontrado';
  @Input() optionLabel: string = 'label';
  @Input() optionValue: string = 'id';
  @Input() showClear: boolean;
  @Input() showFilter: boolean;
  @Input() filterBy: string;
  @Input() cortarTexto: boolean;
  @Input() descricaoSigla: boolean;
  @Input() templateCor: boolean;
  @Input() model: any;
  @Input() modelOptions: any;
  @Output() modelChange = new EventEmitter();

  @ViewChild('contentInput')
  set _content(_input: ElementRef<any>) {
    if (_input) {
      this.contentInput = _input;
    }
  }
  contentInput: ElementRef<any>;

  cortarTextoDropdown(texto: string) {
    if (texto.length > 90) {
      return texto.substring(0, 90) + '...';
    }
    return texto
  }
  onNgModelChange(event: any) {
    this.model = event;
    this.modelChange.emit(event);
  }
}
