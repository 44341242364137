<div class="layout-topbar">
  <a class="layout-topbar-logo" routerLink="">
    <img src="assets/images/brand.png" alt="logo" />
  </a>

  <button
    #menubutton
    class="p-link layout-menu-button layout-topbar-button"
    (click)="layoutService.onMenuToggle()"
  >
    <i class="pi pi-bars"></i>
  </button>

  <button
    #topbarmenubutton
    class="p-link layout-topbar-menu-button layout-topbar-button"
    (click)="layoutService.showProfileSidebar()"
  >
    <i class="pi pi-ellipsis-v"></i>
  </button>

  <div
    #topbarmenu
    class="layout-topbar-menu"
    [ngClass]="{
      'layout-topbar-menu-mobile-active':
        layoutService.state.profileSidebarVisible
    }"
  >
    <div class="flex flex-column justify-content-center align-items-center my-2">
      <span>{{ usuarioLogado.nome }}</span>
    </div>
    <button class="p-link layout-topbar-button" (click)="menu.toggle($event)">
      <i class="pi pi-user"></i>
      <span>Perfil</span>
    </button>
  </div>
</div>
<p-tieredMenu #menu [model]="items" [popup]="true"></p-tieredMenu>
