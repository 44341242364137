import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';

import {BaseInput} from '../base/base-input';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent extends BaseInput {
  @Input() toggleMask: boolean = true;
  @Input() feedback: boolean = false;
  @Input() textAlign: string = 'left';  // left, right, center
  @Input() fullWidth: boolean;
  @Input() control: FormControl;
  @Input() placeholder: string = '';
  @Input() readonly: boolean = false;

  @ViewChild('contentInput')
  set _content(_input: ElementRef<any>) {
    if (_input) {
      this.contentInput = _input;
    }
  }
  contentInput: ElementRef<any>;
  get hasErrors() {
    return this.control?.errors && this.control?.touched;
  }
  get errors() {
    if (!this.control?.errors || !this.control?.touched) return [];
    return Object.keys(this.control?.errors);
  }
}
