import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthFacade } from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private authFacade: AuthFacade) {}
  //   private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
  //     return this.authFacade.refreshToken().pipe(
  //         switchMap((token) => {
  //           const apiReq = request.clone({
  //             headers: request.headers.set(
  //                 'authorization', 'Bearer ' + token.access_token),
  //           });
  //           return next.handle(apiReq);
  //         }),
  //         catchError(() => {
  //           this.authFacade.logout();
  //           return next.handle(request);
  //         }));
  //   }
  intercept(request: HttpRequest<any>, next: HttpHandler):
      Observable<HttpEvent<Object>> {
    return next.handle(request).pipe(
        catchError((httpError: HttpErrorResponse) => {
          if (httpError.status === 401) {
            // if (httpError.error?.error === 'invalid_token') {
            //   return this.handle401Error(request, next);
            // }
          } else if (httpError.status === 403) {
            this.authFacade.logout();
          }

          return throwError(() => httpError);
        }));
  }
}
