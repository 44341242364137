<app-table-skeleton
  *ngIf="!rows && !treeTable"
  [rowsCount]="skeletonRowsCount"
  [checkboxable]="checkboxable"
  [expandable]="expandable"
  [selectable]="selectable"
>
</app-table-skeleton>

<p-table
  [styleClass]="dataTableGridLines ? 'p-datatable-gridlines' : ''"
  *ngIf="rows && !treeTable"
  #table
  responsiveLayout="scroll"
  currentPageReportTemplate="{last} de {totalRecords} itens"
  [columns]="shownedColumns"
  [value]="rows"
  [rows]="rowsPerPage"
  [rowsPerPageOptions]="rowsPerPageOptions"
  [paginator]="paginator && rows.length > 0"
  [showCurrentPageReport]="showCurrentPageReport"
  [selectionMode]="selectionMode"
  [dataKey]="dataKey"
  [stateStorage]="stateStorage"
  [stateKey]="stateKey"
  [scrollHeight]="scrollHeight + 'px'"
  (onRowSelect)="onRowSelect()"
  (onRowExpand)="onRowExpand($event)"
  (onRowReorder)="onRowReorder($event)"
  (onRowUnselect)="onRowSelect()"
  (onHeaderCheckboxToggle)="onRowSelect()"
  [(selection)]="selecteds"
>
  <ng-template pTemplate="caption" *ngIf="caption">
    <div class="table-header" style="text-align-last: center">
      {{ caption }}
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr *ngIf="haveColspanHeader">
      <ng-container *ngFor="let col of columns">
        <th
          *ngIf="col.headerColspan || col.headerColspanCount"
          [attr.colspan]="col.headerColspanCount"
          style="text-align-last: center"
        >
          {{ col.headerColspan }}
        </th>
      </ng-container>
    </tr>
    <tr>
      <th
        style="width: 3rem"
        *ngIf="expandable || selectable || reorderableRows"
      ></th>
      <th style="width: 3rem" *ngIf="checkboxable">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <ng-container *ngFor="let col of columns">
        <th
          *ngIf="!col.sortable"
          [style.width.px]="col.width"
          [class]="col.headerClass"
        >
          <ng-container *ngIf="!col.headerTemplate">
            {{ col.name }}
          </ng-container>
          <ng-container *ngIf="col.headerTemplate">
            <ng-template [ngTemplateOutlet]="col.headerTemplate"></ng-template>
          </ng-container>
        </th>
        <th
          *ngIf="col.sortable"
          [style.width.px]="col.width"
          [pSortableColumn]="col.field"
          [class]="col.headerClass"
        >
          {{ col.name }} <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      </ng-container>
    </tr>
    <tr *ngIf="filterable && rows.length > 0">
      <th style="width: 3rem" *ngIf="expandable || selectable"></th>
      <th style="width: 3rem" *ngIf="checkboxable"></th>

      <ng-container *ngFor="let col of columns">
        <th
          [style.width.px]="col.width"
          pTooltip="Pressione Enter para filtrar"
          *ngIf="checkFilterType(col.type, 'string')"
        >
          <p-columnFilter
            type="text"
            [field]="col.field"
            *ngIf="col.filterable"
            matchMode="contains"
            [showMatchModes]="false"
            [showMenu]="false"
          ></p-columnFilter>
        </th>
        <th
          [style.width.px]="col.width"
          pTooltip="Pressione Enter para filtrar"
          *ngIf="checkFilterType(col.type, 'number')"
        >
          <p-columnFilter
            type="numeric"
            [field]="col.field"
            *ngIf="col.filterable"
            matchMode="contains"
            [showMatchModes]="false"
            [showMenu]="false"
            [minFractionDigits]="col.cellFilterMinFractionDigits"
            [maxFractionDigits]="col.cellFilterMaxFractionDigits"
          ></p-columnFilter>
        </th>
        <th
          [style.width.px]="col.width"
          class="text-center"
          *ngIf="checkFilterType(col.type, 'boolean')"
        >
          <p-columnFilter
            type="boolean"
            [field]="col.field"
            *ngIf="col.filterable"
            matchMode="contains"
            [showMatchModes]="false"
            [showMenu]="false"
          ></p-columnFilter>
        </th>
        <th
          [style.width.px]="col.width"
          pTooltip="Pressione Enter para filtrar"
          *ngIf="checkFilterType(col.type, 'date')"
        >
          <p-columnFilter
            type="date"
            [field]="col.field"
            *ngIf="col.filterable"
            matchMode="contains"
            [showMatchModes]="false"
            [showMenu]="false"
          ></p-columnFilter>
        </th>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowData
    let-columns="columns"
    let-rowIndex="rowIndex"
    let-expanded="expanded"
  >
    <tr
      *ngIf="selectable && reorderableRows"
      [pReorderableRow]="rowIndex"
      [pSelectableRow]="rowData"
    >
      <td>
        <span class="pi pi-bars" pReorderableRowHandle></span>
      </td>
      <ng-container *ngTemplateOutlet="rowContent"></ng-container>
    </tr>
    <tr *ngIf="selectable && !reorderableRows" [pSelectableRow]="rowData">
      <ng-container *ngTemplateOutlet="rowContent"></ng-container>
    </tr>
    <tr *ngIf="!selectable && reorderableRows" [pReorderableRow]="rowIndex">
      <td>
        <span class="pi pi-bars" pReorderableRowHandle></span>
      </td>
      <ng-container *ngTemplateOutlet="rowContent"></ng-container>
    </tr>
    <tr *ngIf="!selectable && !reorderableRows">
      <ng-container *ngTemplateOutlet="rowContent"></ng-container>
    </tr>

    <ng-template #rowContent>
      <td *ngIf="expandable">
        <p-button
          [pRowToggler]="rowData"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
          [text]="true"
          [plain]="true"
          [rounded]="true"
        ></p-button>
      </td>
      <td *ngIf="checkboxable">
        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
      </td>
      <td *ngIf="selectable">
        <p-tableRadioButton [value]="rowData"></p-tableRadioButton>
      </td>
      <ng-container *ngFor="let col of columns">
        <td [class]="col.cellClass" *ngIf="col.isEditable" pEditableColumn>
          <p-cellEditor
            style="text-decoration-line: underline; cursor: pointer"
          >
            <ng-template pTemplate="input">
              <ng-container *ngIf="col.type === 'number'">
                <input
                  pInputText
                  type="text"
                  class="text-right p-inputtext-sm inputfield w-full"
                  [(ngModel)]="rowData[col.field]"
                  (blur)="onRowEdit(rowData, rowIndex)"
                  (keydown)="onKeyPressed($event, rowData, rowIndex)"
                />
              </ng-container>
              <ng-container *ngIf="!col.type">
                <input
                  pInputText
                  type="text"
                  class="p-inputtext-sm inputfield w-full"
                  [(ngModel)]="rowData[col.field]"
                />
              </ng-container>
            </ng-template>
            <ng-template pTemplate="output">
              <div *ngIf="!col.cellTemplate">
                {{ rowData[col.field] }}
              </div>
              <div *ngIf="col.cellTemplate">
                <ng-template
                  [ngTemplateOutlet]="col.cellTemplate"
                  [ngTemplateOutletContext]="{
                    value: rowData[col.field],
                    rowIndex: rowIndex,
                    rowData: rowData
                  }"
                >
                </ng-template>
              </div>
            </ng-template>
          </p-cellEditor>
        </td>
        <td
          [class]="col.cellClass"
          *ngIf="!col.isEditable"
          [style.backgroundColor]="col.cellColor"
        >
          <div *ngIf="!col.cellTemplate">
            {{ rowData[col.field] }}
          </div>
          <div *ngIf="col.cellTemplate">
            <ng-template
              [ngTemplateOutlet]="col.cellTemplate"
              [ngTemplateOutletContext]="{
                value: rowData[col.field],
                rowIndex: rowIndex,
                rowData: rowData
              }"
              s
            >
            </ng-template>
          </div>
          <div *ngIf="col.isCellError && col.errorsPropertyName">
            <div *ngFor="let erro of rowData[col.errorsPropertyName]">
              <div style="color: red">{{ erro }}</div>
            </div>
          </div>
        </td>
      </ng-container>
    </ng-template>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-rowData>
    <tr>
      <td [attr.colspan]="colspan">
        <ng-container
          *ngTemplateOutlet="rowExpanded; context: { rowData: rowData }"
        ></ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="colspan" class="text-center">
        {{ emptyMessage }}
      </td>
    </tr>
  </ng-template>
</p-table>

<app-table-skeleton *ngIf="!rows && treeTable" [rowsCount]="10">
</app-table-skeleton>

<p-treeTable
  #tt
  *ngIf="rows && treeTable"
  [value]="rows"
  [columns]="shownedColumns"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        style="width: 3rem"
        *ngIf="expandable || selectable || reorderableRows"
      ></th>
      <th style="width: 3rem" *ngIf="checkboxable">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <ng-container *ngFor="let col of columns">
        <th
          *ngIf="!col.sortable"
          [style.width.px]="col.width"
          [class]="col.headerClass"
        >
          <ng-container *ngIf="!col.headerTemplate">
            {{ col.name }}
          </ng-container>
          <ng-container *ngIf="col.headerTemplate">
            <ng-template [ngTemplateOutlet]="col.headerTemplate"></ng-template>
          </ng-container>
        </th>
        <th
          *ngIf="col.sortable"
          [style.width.px]="col.width"
          [pSortableColumn]="col.field"
          [class]="col.headerClass"
        >
          {{ col.name }} <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowNode
    let-rowData="rowData"
    let-columns="columns"
  >
    <tr>
      <td *ngFor="let col of columns; let i = index" [class]="col.cellClass">
        <div *ngIf="!col.cellTemplate">
          <p-treeTableToggler
            [rowNode]="rowNode"
            *ngIf="i === 0"
          ></p-treeTableToggler>
          {{ rowData[col.field] }}
        </div>
        <div *ngIf="col.cellTemplate">
          <p-treeTableToggler
            [rowNode]="rowNode"
            *ngIf="i === 0"
          ></p-treeTableToggler>
          <ng-template
            [ngTemplateOutlet]="col.cellTemplate"
            [ngTemplateOutletContext]="{
              value: rowData[col.field],
              rowIndex: i,
              rowData: rowData
            }"
          >
          </ng-template>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="colspan" class="text-center">
        {{ emptyMessage }}
      </td>
    </tr>
  </ng-template>
</p-treeTable>
