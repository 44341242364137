<router-outlet></router-outlet>
<ngx-ui-loader></ngx-ui-loader>
<p-toast></p-toast>
<p-confirmDialog key="confirmation"></p-confirmDialog>
<!-- 
<ngx-loading-bar
  height="4px"
  [includeSpinner]="false"
  class="loading-bar"
></ngx-loading-bar>

<ngx-loading-bar
  height="4px"
  [includeSpinner]="false"
  ref="router"
  class="loading-bar"
></ngx-loading-bar>

<ngx-loading-bar
  height="4px"
  [includeSpinner]="false"
  ref="http"
  class="loading-bar"
></ngx-loading-bar> -->