<ng-container *ngIf="control">
  <div class="p-inputgroup">
    <input
      #contentInput
      class="p-inputtext-lg inputfield w-full"
      pInputText
      appAutoFocus
      currencyMask
      [options]="options"
      [autofocus]="autoFocus"
      [formControl]="control"
      [readonly]="readonly"
      [placeholder]="placeholder"
      [maxlength]="maxlength"
      [minlength]="minlength"
      [ngClass]="{
        'ng-invalid ng-dirty': hasErrors,
        'text-right': textAlign === 'right',
        'text-center': textAlign === 'center',
        'text-left': textAlign === 'left'
      }"
      (blur)="onBlur($event)"
      (keydown)="onKeyDown($event)"
      (keyup)="onKeyUp($event)"
    />
    <p-button
      *ngIf="showButton"
      type="button"
      [icon]="iconButton"
      (onClick)="onClick()"
      [disabled]="buttonDisabled"
    >
    </p-button>
  </div>
  <small
    style="float: right"
    *ngIf="maxlength"
    [ngClass]="{
      'p-error': hasErrors
    }"
    >{{ control.value?.toString().length ?? 0 }}/{{
      maxlength.toString()
    }}</small
  >
  <ng-container *ngFor="let error of errors">
    <small class="p-error">{{ getErrorMessage(control, error) }}<br /></small>
  </ng-container>
</ng-container>
